.containerMoreProj {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.headerDiv {
  background: linear-gradient(263.41deg, #214ce7 0.75%, #5c48ad 97.9%);
}
.headerH1 {
  color: rgba(255, 255, 255, 1);
  font-size: 90px;
  font-weight: 400;
  text-align: center;
  font-family: var(--font-primary);
}
.containerMoreProj p {
  max-width: 45vw;
  font-family: var(--font-secondary);
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 31.37px;
  letter-spacing: -0.01em;
}
.containerMoreProj img {
  max-width: none;
}
.containerMoreProj .projh1 {
  color: rgba(255, 255, 255, 1);
  text-align: unset;
  margin: 0 5rem;
}

.containerMoreProj p,
.containerMoreProj h1,
.containerMoreProj h2,
.containerMoreProj h3,
.containerMoreProj h4 {
  color: rgba(255, 255, 255, 1);
}
@media (max-width: 1150px) {
  .containerMoreProj h4 {
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 28.64px;
    letter-spacing: 0.01em;
  }
  .containerMoreProj p {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 17.73px;
    letter-spacing: 0.01em;
    width: 70vw;
  }
  .headerH1 {
    font-size: 4.2rem;
    padding-top: 2.4rem;
  }
}

@media (max-width: 800px) {
  .containerMoreProj p {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 17.73px;
    letter-spacing: 0.01em;
    /* max-width: fit-content(calc(10rem)); */
    /* min-width: max-content; */
    max-width: calc(100% + 10rem);
    margin: 0;
    padding: 0;
  }
}
