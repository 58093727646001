.home-picXtext-div {
  border-radius: 151px;
  border: 2px solid rgba(92, 72, 173, 1);
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  margin: 2rem auto;
  padding: 3rem;
  flex-wrap: nowrap;
  width: 90%;
  overflow: clip;
}

.home-picXtext-div.left {
  flex-direction: row-reverse;
  gap: 3rem;
}

.home-picXtext-div.about {
  border-radius: 20px;
  margin: 1rem auto;
  box-shadow: 0px 8px 8px 0px rgba(166, 198, 211, 0.25);
  padding: 1rem;
}

.home-picXtext-innder-div {
  /* width: 100%; */
}

.home-picXtext-h1 {
  font-family: var(--font-primary);
  font-size: 6rem;
  font-weight: 400;
  line-height: 125px;
  letter-spacing: 0.01em;
  /* text-align: left; */
}
.home-picXtext-h1.about {
  /* font-size: clamp(2rem, 5vw, 4rem); */
  font-family: var(--font-primary);
  font-size: 76px;
  font-weight: 400;
  line-height: 95.3px;
}

.home-picXtext-p {
  font-family: var(--font-secondary);
  font-size: 2.5rem;
  line-height: 35px;
}
.home-picXtext-p.about {
  /* font-size: clamp(1.5rem, 3vw, 2rem); */
  font-family: var(--font-secondary);
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0.01em;

  margin-bottom: 2rem;
  width: 100%;
}

.home-picXtext-img {
  width: 35%;
  border-radius: 75px;
}
.home-picXtext-img.about {
  height: auto;
  padding: 1rem 0;
}

@media (max-width: 950px) {
  .home-picXtext-img {
    border-radius: 50px;
    width: 50%;
  }
  .home-picXtext-h1 {
    font-size: 4rem;
  }
  .home-picXtext-p {
    font-size: 1.8rem;
    width: 90%;
  }
}
@media (max-width: 786px) {
  .home-picXtext-div {
    flex-direction: row;
    margin: 0 auto;
    padding: 1rem;
    flex-wrap: wrap;
    border-radius: 75px;
  }

  .home-picXtext-div.left {
    flex-direction: row-reverse;
  }

  .home-picXtext-h1 {
    font-size: 5rem;
    line-height: 62.7px;
    /* text-align: center; */
  }
  .home-picXtext-h1.about {
    font-size: 4.6rem;
    line-height: 57.68px;
  }

  .home-picXtext-p {
    font-size: 2rem;
    line-height: 27.28px;
  }
  .home-picXtext-p.about {
    font-size: 1.8rem;
    line-height: 24.55px;
  }

  .home-picXtext-img {
    width: 70%;
    margin: 0 auto;
    border-radius: 0;
  }
}
@media (max-width: 320px) {
  .home-picXtext-h1 {
    font-size: 4.5rem;
    line-height: 60px;
  }
  .home-picXtext-h1.about {
    font-size: 4.6rem;
    line-height: 57.68px;
  }

  .home-picXtext-p {
    font-size: 1.8rem;
    line-height: 25px;
  }
  .home-picXtext-p.about {
    font-size: 1.8rem;
    line-height: 24.55px;
  }
}
