.heroSectionHome {
  height: 100vh;
}
.siteTitleHero {
  font-family: var(--font-primary);
  font-size: 15rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 0;
}
.bigTextEv {
  transform: rotate(-1.66deg);
  display: block;
  width: fit-content;
  background: rgba(255, 186, 58, 0.77);
  margin: 0 auto;
}

.bitTextHero {
  font-family: var(--font-secondary);
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray);
  text-align: center;
}

.feat-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.5rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.feat-hero > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feat-home-text {
  font-family: var(--font-secondary);
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 38.19px;
  text-align: center;

  color: var(--dark-blue);
  margin-top: 1rem;
}
.toBeshown {
  display: none;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .siteTitleHero {
    font-size: 13rem;
  }

  .toBeHidden {
    /* visibility: hidden; */
    display: none !important;
  }
}
@media (max-width: 768px) {
  .siteTitleHero {
    font-size: 7.5rem;
    line-height: 94.04px;
  }
  .bitTextHero {
    font-size: 2rem;
    line-height: 27.28px;
  }
  .feat-hero {
    gap: 2.5rem;
  }

  .feat-home-text {
    font-size: 1.2rem;
    line-height: 16.37px;
  }
  .toBeshown {
    /* visibility: hidden; */
    display: block !important;
    /* width: 50%; */
    margin: 5rem 2rem;
  }
}
@media (max-width: 320px) {
  .siteTitleHero {
    font-size: 5.5rem;
    line-height: 75px;
  }
  .heroSectionHome {
    height: 70vh;
  }
}
