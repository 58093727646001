a.aimgProj {
  display: block;
  width: 20%;
  margin: 2rem;
}

a.aimgProj .imgProj {
  width: 100%;
  box-shadow: 0px 4px 40px 0px rgba(177, 186, 217, 1);
}
.aimgProj {
  text-decoration: none;
  color: inherit;
}
.Projh2 {
  font-family: var(--font-secondary);
  font-size: 44px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.01em;
  background: linear-gradient(90deg, #5c48ad 1.69%, #214ce7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.projh1 {
  text-align: center;
  font-family: var(--font-primary);
  font-size: 4.6rem;
  font-weight: 400;
  line-height: 150px;
  letter-spacing: 0.01em;
  color: rgba(46, 46, 46, 1);
}

.img-arr-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 1400px) {
  .imgProj {
    width: 40%;
  }
}
@media (max-width: 800px) {
  .projh2 {
    font-size: 21px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .projh1 {
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 63.95px;
    letter-spacing: 0.01em;
  }

  .imgProj {
    width: 100%;
  }
  a.aimgProj {
    width: fit-content;
  }
}
@media (max-width: 520px) {
  .imgProj {
    width: 85%;
  }
  .projh1 {
    width: 75%;
    margin: auto;
    line-height: 40px;
    font-size: 2.2rem;
  }
}
