.servicesDiv {
  max-width: max-content;
  margin: auto;
  padding: 2rem;
  border-radius: 52px;
  text-align: center;
}
.heroSection .menu_header span,
.heroSection .menu-list li a {
  color: aliceblue !important;
}
.heroSection {
  background-image: url(../img/service-Hero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.servicesDiv h1 {
  color: rgba(255, 255, 255, 1);
  font-family: var(--font-primary);
  font-size: 9rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01em;
}

.servicesDiv p {
  font-family: var(--font-primary);
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: aliceblue;
  margin-bottom: 2rem;
}

/* Button styling */
.btn-div {
  display: flex;
  justify-content: center;
}
/* TODO menu text color changed on service page  */

@media (max-width: 1400px) {
  .servicesDiv {
    max-width: max-content;
  }
  .servicesDiv h1 {
    font-size: 6rem;
  }

  .servicesDiv p {
    font-size: 3rem;
    width: 90%;
    margin: auto;
    margin-bottom: 2rem;
  }
}

@media (max-width: 1300px) {
  .servicesDiv {
    max-width: 80%;
  }
  .servicesDiv h1 {
    font-size: 5rem;
  }

  .servicesDiv p {
    font-size: 2.6rem;
    /* width: 90%; */
  }
}
@media (max-width: 768px) {
  .servicesDiv {
    padding: 1.5rem;
  }

  .servicesDiv h1 {
    font-size: 4.2rem;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .servicesDiv p {
    font-size: 2rem;
    max-width: 70%;
    font-weight: 400;
    line-height: 25.08px;
    letter-spacing: 0.01em;
  }
}

@media (max-width: 480px) {
  .servicesDiv {
    padding: 1rem;
    margin-top: 20vh;
    /* margin-top: 1rem; */
  }

  .servicesDiv h1 {
    font-size: 2.5rem;
    max-width: fit-content;
    font-weight: 600;
  }

  .servicesDiv p {
    font-size: 1.5rem;
    max-width: fit-content;
  }
}
