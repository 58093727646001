/* blob code */
/* .contact-form {
  position: relative; 
  padding: 2rem;
  border-radius: 10px;
  overflow: hidden; 
}

.namediv::before {
  content: "";
  position: absolute;
  top: -65px;
  left: 6px;
  width: 63rem;
  height: 27.5rem;
  border-radius: 50%;
  background: rgba(255, 186, 58, 0.3);
  z-index: 0;
  -webkit-filter: blur(85px);
  filter: blur(85px);
}

.contact-form::before {
  content: "";
  position: absolute;
  top: 182px;
  left: 700px;
  width: 57rem;
  height: 27.5rem;
  border-radius: 50%;
  background: rgba(105, 174, 231, 0.5);
  z-index: 0;
  -webkit-filter: blur(85px);
  filter: blur(85px);
} */

/* end blob code */
.backupMail {
  color: #214ce7;
  text-decoration: underline;
  font-weight: bold;
}

.backupMail:hover {
  color: #214ce7;
  text-decoration: none;
}
.contacth1 {
  font-family: var(--font-primary);
  font-size: 11rem;
  font-weight: 400;
  line-height: 137.93px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 auto;
}
.ctaContactForm::before {
  background: rgba(105, 174, 231, 0);
}
.ctaContactForm {
  background: linear-gradient(90deg, #5c48ad 0%, #214ce7 100%);
}
.ctaContactForm .backupMail,
.ctaContactForm label {
  color: white;
}
.ctaContactForm .btn-btn {
  background: rgba(255, 255, 255, 1);
  color: var(--gray);
}
.contactH3 {
  font-family: var(--font-secondary);
  font-size: 44px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 5rem auto 0 auto;
  background: linear-gradient(90deg, #5c48ad 0%, #214ce7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-form {
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 20px;
  padding: 2rem;
  border-radius: 10px;
  z-index: 1;
  margin: 0 25rem;
  flex-direction: column;
}
.namediv::before {
  background: rgba(255, 186, 58, 0);
}
.namediv,
.emailDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.firstRow {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}
.messageDiv {
  grid-column: span 2; /* Message textarea spans across both columns */
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
  z-index: 0;
}

.form-input,
.form-textarea {
  z-index: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the width */
}

.form-textarea {
  min-height: 100px;
  resize: vertical;
}

.Btn {
  grid-column: span 2; /* Button spans across both columns */
  padding: 10px 20px;
  background-color: #5c48ad;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.Btn:hover {
  background-color: #214ce7;
}

.form-message {
  grid-column: span 2; /* Message spans across both columns */
  text-align: center;
  font-size: 14px;
  color: red;
}

label span {
  color: red;
  font-family: var(--font-primary);
  font-size: 2rem;
  font-weight: 700;
  line-height: 45px;
}

label {
  font-family: var(--font-primary);
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  /* text-align: left; */
}

/* Media queries for responsiveness */
@media (max-width: 1400px) {
  .contact-form {
    margin: 0 auto;
    padding: 0 5rem;
  }
  .firstRow {
    gap: 1rem;
  }
}
@media (max-width: 800px) {
  .contacth1 {
    font-size: 6rem; /* Reduce font size on smaller screens */
    line-height: 8rem;
  }
  .firstRow {
    flex-wrap: wrap;
  }
  .namediv {
    margin-right: 0;
  }
  .contactH3 {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
    line-height: 3rem;
    margin: 3rem auto 1rem auto; /* Adjust spacing for smaller screens */
  }

  .ctaContactForm .contact-form {
    margin: 0 auto;
  }
  .contact-form {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    padding: 1rem; /* Reduce padding */
    width: 90%; /* Make form width responsive */
    max-width: 100%; /* Allow max width to be full */
    margin: 1rem auto;
  }

  .Btn {
    width: 100%; /* Button spans full width */
    padding: 15px 0;
    font-size: 1.6rem; /* Increase button font size for readability */
  }
}

@media (max-width: 480px) {
  .contacth1 {
    font-size: 4.5rem; /* Further reduce font size for extra small screens */
    line-height: 6rem;
  }

  .contactH3 {
    font-size: 2rem; /* Further reduce font size for smaller screens */
    line-height: 2.5rem;
  }

  .contact-form {
    padding: 1rem; /* Further reduce padding for extra small screens */
  }

  label {
    font-size: 1.4rem; /* Reduce label font size */
    line-height: 2rem;
  }

  .form-message {
    font-size: 1.2rem; /* Reduce font size for messages */
  }
}
