.ourProSection {
  background: rgba(247, 251, 255, 1);
  /* padding: 1rem;
  max-width: 1200px;
  margin: 0 auto; */
}
.btn-contact-proc .btn-btn {
  margin: 0 auto;
}
.title-our {
  width: 100%;
  /* max-width: 722px; */
  margin: 0 auto;
  text-align: center;
}
.h2-build-win-web {
  font-size: 6rem;
}
.btn-contact-proc {
  padding: 2rem;
}
.titlel-h3-our {
  /* font-size: clamp(1.5rem, 2.5vw, 2.75rem); */
  line-height: 1.2;
  padding-top: 2rem;
  font-family: Nunito;
  font-size: 4.4rem;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.01em;
  background: linear-gradient(90deg, #5c48ad -1.69%, #214ce7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titlel-h1-our {
  /* font-size: clamp(2.5rem, 5vw, 6.875rem); */
  font-family: Marcellus;
  font-size: 6rem;
  font-weight: 700;
  line-height: 137.93px;
  letter-spacing: 0.01em;
  text-align: center;
  width: 70%;
  margin: 3rem auto;
}

.titlel-p-our {
  /* font-size: clamp(1.25rem, 2vw, 2.5rem);  */
  font-family: Nunito;
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0.01em;
  text-align: center;
  /* margin: auto 5rem; */
  width: 60%;
  margin: 3rem auto;
}

.p-dots {
  font-family: Marcellus;
  font-size: 10.5rem;
  font-weight: 400;
  line-height: 131.66px;
  letter-spacing: 0.2em;
  text-align: center;
}

.middleBoxProc {
  border-radius: 20px;
  margin: 2rem 15rem;
  font-size: clamp(1.5rem, 3vw, 3.8125rem);
  padding: 0 10rem;
  text-align: center;
  background: linear-gradient(90deg, #214ce7 0%, #5c48ad 100%);
  font-family: Marcellus;
  font-weight: 400;
  line-height: 94.04px;
  letter-spacing: 0.01em;
  border-radius: 6.4rem;
  position: absolute;
  top: 40%;
  right: 15%;
}
.heStyle {
  flex-direction: row-reverse;
}
.middleBoxProc span {
  font-size: clamp(2rem, 4vw, 5.625rem);
  font-family: Parisienne;
  font-weight: 400;
  line-height: 122.61px;
  letter-spacing: 0.01em;
  text-align: center;
}

.bottomBoxProc {
  border-radius: 20px;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 2rem 1rem;
  margin: 1rem;
  background: linear-gradient(80.08deg, #0f172a 14.62%, #334f90 78.4%);
  display: flex;
  gap: 1rem;
  justify-content: center;
  max-width: 1200px;
  margin: 1rem auto;
  position: absolute;
  top: 40%;
  right: 26%;
}

.bottomBoxOuterBox {
  display: flex;
  justify-content: center;
}

.procFir,
.procsec,
.procLast {
  font-family: VT323;
  font-size: clamp(2.5rem, 5vw, 40px);
  font-weight: 400;
  line-height: 61px;
  letter-spacing: 0.01em;
  margin: 0;
}

.procFir {
  color: rgba(255, 186, 58, 0.77);
}

.procsec {
  color: rgba(123, 98, 222, 1);
}

.procLast {
  color: rgba(255, 255, 255, 1);
}

@media (max-width: 1600px) {
  .titlel-h1-our {
    line-height: normal;
    width: auto;
  }
}
@media (max-width: 1300px) {
  .middleBoxProc {
    top: 36%;
    right: 5%;
  }
  .bottomBoxProc {
    top: 34%;
    right: 12%;
  }
  .titlel-h1-our {
    font-size: 5rem;
    line-height: 87.77px;
  }
  .titlel-p-our {
    font-size: 3rem;
    width: 65%;
    margin: 0 auto;
  }
}
@media (max-width: 1000px) {
  .bottomBoxProc {
    right: 5%;
  }
  .titlel-h1-our {
    font-size: 4.5rem;
    margin: 0 2rem;
  }
}
@media (max-width: 900px) {
  .middleBoxProc {
    margin: 0;
    font-size: 2.5rem;
    right: 17%;
    top: 34%;
    padding: 0px 10rem;
  }
  .bottomBoxProc {
    display: none !important;
    top: 34%;
    right: 3%;
  }
}
@media (max-width: 768px) {
  .ourProSection-Title {
    padding: 0 1rem; /* Adjust padding for smaller screens */
  }

  .titlel-h3-our {
    font-size: 2.3rem;
  }

  .titlel-h1-our {
    font-size: 4.5rem;
    line-height: 60px;
  }

  .titlel-p-our {
    font-size: 2rem;
    width: fit-content;
    line-height: 30px;
  }

  .p-dots {
    font-size: 6rem;
  }

  .middleBoxProc {
    margin: 0 2rem; /* Adjust margins for smaller screens */
    font-size: 2rem; /* Adjust font size for smaller screens */
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  .middleBoxProc span {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }

  .procFir,
  .procsec,
  .procLast {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .bottomBoxProc,
  .middleBoxProc {
    display: none !important; /* Hide these elements on smaller screens */
  }

  .h2-build-win-web {
    font-size: 3.5rem;
  }
}
@media (max-width: 400px) {
  .h2-build-win-web {
    font-size: 3rem;
  }
  .titlel-h1-our {
    font-size: 6rem;
  }
  .p-dots {
    margin: 0;
  }
  .titlel-p-our {
    margin-bottom: 2rem;
  }
  .titlel-h1-our {
    font-size: 2rem;
    line-height: normal;
    margin-bottom: 2rem;
  }
}
