/* Class Styles */
.container-privacy {
  font-family: var(--font-secondary);
  line-height: 1.6;
  margin: 20px;
  color: var(--gray);
  background-color: var(--off-white);
}

.main-title-privacy {
  font-family: var(--font-primary);
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: var(--light-blue);
}

.section-title-privacy {
  font-family: var(--font-primary);
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--purple);
}

.paragraph-privacy {
  font-family: var(--font-secondary);
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: var(--gray);
}

.link-privacy {
  color: var(--light-blue);
  text-decoration: none;
}

.link-privacy:hover {
  text-decoration: underline;
}

.bold-privacy {
  font-weight: bold;
}
