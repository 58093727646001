.section {
  border-radius: 25px;
  height: fit-content;
  padding: 2rem;
}
.section.access {
  text-align: center;
}
.section.access img {
  width: 20%;
}
.section.wpdev {
  width: auto;
  height: auto;
}
.section.purp.webdev {
  grid-column: 1;
  grid-row: 1 / -2;
}
.section.seoCard {
  background: linear-gradient(115.49deg, #000000 2.53%, #545454 99.18%);
  display: flex;
  flex-direction: column;
}
.seoCard img {
  align-self: end;
  border-radius: 25px;
}
.section.purp.desLog {
  overflow-y: clip;
  display: flex;
  flex-direction: column;
  /* height: 70%; */
}
.section.purp.desLog img {
  align-self: flex-end;
  border-radius: 15px;
}
.section.purp.desLog,
.section.groupWorking {
  flex-wrap: wrap;
  justify-content: center;
}
.section.purp {
  background: conic-gradient(
    from 135deg at 50% 50%,
    #889be1 0deg,
    #a26ce4 180deg,
    #889be1 360deg
  );
}
.section.cloud img {
  width: 85%;
}
.section.cloud {
  position: relative;
  text-align: center;
  color: #ffffff;
  padding: 0;
}
.section.cloud h1 {
  background: linear-gradient(270deg, #fff 27.94%, #fff 97.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
  position: absolute;
  top: 25%;
  right: 25%;
}
.section.cloud p {
  position: absolute;
  z-index: 1;
  width: 80%;
  top: 50%;
  left: 10%;
  text-align: center;
}
.section.groupWorking h1,
.section.groupWorking p {
  display: none;
}

.section.groupWorking {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
}
.serviceCardsSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.section.purp h1,
.section.seoCard h1 {
  background: linear-gradient(270deg, #ffffff 27.94%, #ffffff 97.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section h1 {
  font-family: var(--font-secondary);
  font-size: 45px;
  font-weight: 800;
  line-height: 61.38px;
  /* text-align: left; */
  background: linear-gradient(270deg, #873de1 27.94%, #ab7ce4 97.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section.six16 {
  text-align: center;
}
.section.seoCard p {
  color: white;
}
.section p {
  font-family: var(--font-secondary);
  font-size: 21px;
  font-weight: 500;
  line-height: 28.64px;
  /* text-align: left; */
}

.section img {
  width: 70%;
}
@media (max-width: 1500px) {
  .section h1 {
    font-size: 35px;
    font-weight: 800;
    line-height: 47.74px;
  }
  .section p {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.82px;
  }
  .section.cloud h1 {
    right: 20%;
  }
}
@media (max-width: 1024px) {
  .section.cloud h1 {
    right: 15%;
    font-size: 3rem;
  }
  .section.cloud img {
    width: 100%;
  }
  .section.cloud p {
    word-spacing: 3px;
    font-size: 1.5rem;
  }
  /* Reduce height of hero section */
  .heroSection {
    /* height: 80vh; */
  }

  .serviceCardsSection {
    grid-template-columns: repeat(2, 1fr); /* Show 2 columns */
  }
}

@media (max-width: 900px) {
  .section.cloud h1 {
    font-size: 4rem;
    right: 20%;
  }
  .section.cloud p {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  .section.cloud h1 {
    font-size: 3rem;
  }
  .section.cloud p {
    font-size: 1.5rem;
  }
  .serviceCardsSection {
    grid-template-columns: 1fr; /* Single column for mobile */
  }

  .servicesDiv {
    max-width: 90%;
  }
}

@media (max-width: 600px) {
  .section.cloud h1 {
    font-size: 5rem;
    right: 21%;
    top: 28%;
  }
  .section.cloud p {
    font-size: 3rem;
    line-height: 30px;
  }
}
@media (max-width: 480px) {
  /* Handle extremely small screens */
  .servicesDiv {
    /* max-width: 100%; */
    padding: 1rem;
  }

  .heroSection {
    height: 100vh;
  }
  .section.cloud h1 {
    font-size: 3rem;
    right: 27%;
    top: 28%;
  }
  .section.cloud p {
    font-size: 1.8rem;
    line-height: 20px;
  }
}
@media (max-width: 390px) {
  .section.cloud h1 {
    font-size: 2.5rem;
    right: 25%;
    top: 28%;
  }
  .section.cloud p {
    word-spacing: 2px;
    font-size: 1.4rem;
    line-height: 15px;
  }
}
/* TODO around 1500px the cloud text starts getting messed up , move it around. */
