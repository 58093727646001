.btn-btn {
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 52px;
  background: linear-gradient(90deg, #214ce7 0%, #5c48ad 100%);
  width: 100%;
  /* max-width: 268.65px; before changes*/
  max-width: 30rem;
  padding: 0.8rem 1.2rem;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.6;
  color: rgba(255, 255, 255, 1);
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-btn.white {
  background: rgba(255, 255, 255, 1);
  color: var(--gray);
}

.aCls {
  color: inherit; /* Use inherited color */
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .btn-btn {
    padding: 0.7rem 1rem;
    max-width: 100%;
  }
}
@media (max-width: 1600px) {
  .aCls {
    font-size: 2.6rem;
  }
}
