/* Portfolio Styles */
.imgArrdiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.protH3 {
  font-family: var(--font-secondary);
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  background: linear-gradient(90deg, #5c48ad 0%, #214ce7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 3rem auto 1rem;
  width: 100%;
}

.protH1 {
  margin: 0 auto 1rem;
  font-family: var(--font-primary);
  font-size: 9rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01em;
  text-align: center;
  width: 100%;
  max-width: 800px;
}

.portQuote {
  font-family: var(--font-secondary);
  font-size: clamp(1.4rem, 1.5vw, 1.8rem); /* Responsive font size */
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-align: center;
  background: linear-gradient(90deg, #5c48ad 0%, #214ce7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1rem auto;
  width: 100%;
  max-width: 800px;
}

/* Additional Styles for Image Responsiveness */
.imgArrdiv img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .protH3 {
    font-size: 23px;
    line-height: 40px;
  }
  .protH1 {
    font-size: 60px;
    line-height: 75.23px;
  }
  .protH3,
  .protH1,
  .portQuote {
    max-width: 90%;
  }
}
