.projDiv {
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
}
.projDetails {
  /* creat the blob */
}
.aboutTag {
  font-family: var(--font-secondary);
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 28.64px;
  letter-spacing: 0.01em;
  color: rgba(92, 72, 173, 1);
  width: fit-content;
  background: rgba(92, 72, 173, 0.25);
  border-radius: 6rem;
  padding: 0.5rem;
  /* text-align: left; */
}
.projHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.projDetails li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.projLogo {
  width: 20rem;
  margin: 1rem 0;
}

.projTagline {
  font-size: 1.8rem;
  margin: 1rem 0;
}

.startEatingBtn,
.learnMoreBtn {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  margin: 1rem 0.5rem;
  border: none;
  cursor: pointer;
}

.startEatingBtn {
  background-color: #214ce7;
  color: #fff;
}

.learnMoreBtn {
  background-color: #fff;
  color: #214ce7;
  border: 1px solid #214ce7;
}

.mealsDelivered {
  font-size: 1rem;
  margin-top: 1rem;
}

.featuredIn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.featuredIn img {
  height: 30px;
  opacity: 0.6;
}

.projScreenshots {
  display: flex;
  justify-content: space-evenly;
  margin: 3rem 0;
  flex-wrap: wrap;
  row-gap: 2rem;
}

.projScreen1,
.projScreen2 {
  width: 30rem;
  border-radius: 8px;
}

.projDetails {
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 2rem;
  text-align: left;
}

.projDetails ul {
  list-style: none;
  padding: 0;
}
.projDetialsA {
  font-family: var(--font-primary);
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 38.19px;
  letter-spacing: 0.01em;
}
.projDetails a {
  /* text-decoration: none;
  color: #0f172a; */
  /* later change the color and style of the link */
}
.projDetails a,
.projDetails li {
  font-family: var(--font-primary);
  font-size: 28px;
  font-weight: 400;
  line-height: 38.19px;
  letter-spacing: 0.01em;
}
@media (max-width: 800px) {
  .projDetails li div {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 21.32px;
    letter-spacing: 0.01em;
  }
  .projDetails li {
    font-size: 2rem;
    font-weight: 700;
    line-height: 25.08px;
    letter-spacing: 0.01em;
  }
}
@media (max-width: 450px) {
  .projDetails li {
    font-size: 1.8rem;
  }
  .projScreen1,
  .projScreen2 {
    width: 25rem;
  }
}
