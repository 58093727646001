.liQnA {
  margin: 1rem 0;
  background-color: var(--off-white);
  border: 1px solid black;
}
.ulQnA {
  width: 100%;
  margin: 0 auto;
}
.h1QnA {
  font-size: 6rem;
}
.divQnA {
  margin: 2rem;
  background-color: white;
  border: 3px solid black;
  padding: 3rem;
  display: flex;
  justify-content: center;
}
.divMainList {
  width: 100%;
}
.innder_div {
  width: 100%;
}
.aQnA {
  background-color: var(--off-white);
  font-size: 5rem;
  width: 100%;
  text-decoration: none;
  color: inherit;
  border: none;
  text-align: inherit;
}
.QnAAnswer {
  background-color: var(--off-white);
  font-size: 3rem;
  margin: 0 auto;
  width: fit-content;
}
.mainContentDiv {
  display: flex;
  gap: 2rem;
}
@media (max-width: 900px) {
  .h1QnA {
    font-size: 5rem;
  }
  .aQnA {
    font-size: 3.8rem;
  }
  .QnAAnswer {
    font-size: 2.6rem;
  }
}
@media (max-width: 500px) {
  .h1QnA {
    font-size: 5rem;
  }
  .aQnA {
    font-size: 2.6rem;
  }
  .QnAAnswer {
    font-size: 2rem;
    line-height: 35px;
  }
}
