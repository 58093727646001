/* footer.css */
.footerContainer {
  background: #0f172a;
  color: white;
  width: 100%;
  padding: 4rem 1rem;
  box-sizing: border-box;
}

.footerWrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.footerDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.footerSection {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
}

.footerH3 {
  font-size: clamp(1.8rem, 2.5vw, 2.4rem);
  margin-bottom: 1rem;
  color: var(--white);
}

ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.socialsLi {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.socialsLi a {
  color: white;
  font-size: clamp(1.5rem, 2vw, 2rem);
}

.footerSubMenu a {
  color: white;
  text-decoration: none;
  font-size: clamp(1.4rem, 1.8vw, 1.8rem);
  line-height: 2;
}

.footerSubMenu a:hover {
  text-decoration: underline;
}

.copyright {
  text-align: center;
  margin-top: 2rem;
  font-size: clamp(0.8rem, 1vw, 1rem);
}

@media screen and (max-width: 768px) {
  .footerDiv {
    flex-direction: column;
    align-items: center;
  }

  .footerSection {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .socialsLi {
    justify-content: center;
  }

  .footerSubMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .footerContainer {
    padding: 2rem 1rem;
  }

  .footerSection {
    max-width: 100%;
  }
}
