/* CTA Styles */
.cta-div {
  background: linear-gradient(263.41deg, #214ce7 0.75%, #5c48ad 97.9%);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2rem;
  /* width: 100%; */
  /* box-sizing: border-box; */
  align-items: center;
}
.bigdiv {
  background: linear-gradient(263.41deg, #214ce7 0.75%, #5c48ad 97.9%);
}

.cta-h1 {
  font-family: var(--font-primary);
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em;
  /* text-align: left; */
  color: rgba(255, 255, 255, 1);
  margin: 0 0 1rem 0;
  width: 100%;
}

.cta-h1.ourMission span {
  color: rgba(255, 186, 58, 1);
}
.cta-h1.ourMission {
  color: var(--gray);
}

.cta-p {
  font-family: var(--font-secondar);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
  /* text-align: left; */
  color: rgba(255, 255, 255, 1);
  width: 100%;
}

.cta-p.ourMission {
  color: rgba(66, 66, 66, 1);
}

.cta-div.ourMission {
  background: var(--off-white);
}

.cta-div.ourMission.SecondCls {
  margin: 0 1rem 1rem;
  box-shadow: 0px 0px 20px 0px rgba(105, 174, 231, 0.5);
  border-radius: 14px;
  background: rgba(255, 255, 255, 1);
  padding: 2rem;
  width: calc(100% - 2rem);
  box-sizing: border-box;
}

/* Responsive Design for CTA */
@media (min-width: 481px) {
  .cta-h1 {
    font-size: 3rem;
  }

  .cta-p {
    font-size: 1.8rem;
  }
}

@media (max-width: 1400px) {
  .cta-div {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  .cta-h1 {
    font-size: 7rem;
    width: fit-content;
    margin: auto;
  }

  .cta-p {
    font-size: 2rem;
  }
}
@media (max-width: 800px) {
  .cta-h1 {
    font-size: 5rem;
    font-weight: 400;
    line-height: 62.7px;
    letter-spacing: 0.01em;
  }
  .cta-h1 {
    font-size: 5rem;
    font-weight: 400;
    line-height: 62.7px;
    letter-spacing: 0.01em;
  }
}
