:root {
  /* Color Palette */
  --white: #ffffff;
  --off-white: #f1f5fd;
  --light-blue: #214ce7;
  --dark-blue: #0f172a;
  --purple: #5c48ad;
  --gray: #2e2e2e;

  /* Font Settings */
  --font-primary: "Marcellus", "Varela Round";
  --font-secondary: "Nunito Sans", "Varela Round";
  --font-tertiary: "Exo 2", "Varela Round";
  --font-mono: "VT323", "Varela Round";
  --font-fallback: "Miriam Libre", "Varela Round";
  /* 
  --font-primary: "Birzia_Family";
  --font-secondary: "Birzia_Family";
  --font-tertiary: "Birzia_Family";
  --font-mono: "Birzia_Family";
  --font-fallback: "Birzia_Family"; */

  /* Font Size (1rem = 10px) */
  font-size: 62.5%; /* Converts 1rem to 10px */
}

body {
  font-family: var(--font-secondary);
  font-size: 1.6rem; /* Default font size: 16px */
  color: var(--gray);
  background-color: var(--off-white);
  line-height: 1.6;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* direction: rtl;
  text-align: right; */
}
.highlight {
  color: var(--light-blue);
}

/* For when English is selected */
body[dir="ltr"] {
  direction: ltr;
  text-align: left;
}
