.about-div {
  width: 100%;
  margin: 0 auto;
  /* padding: 0 10px; */
}
.mobileTeam {
  display: none;
}
.aboutH1Team {
  font-family: var(--font-primary);
  font-size: clamp(3rem, 6vw, 6rem);
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 2rem;
}

.imgDivTeam {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
}

.innderDivImg {
  flex: 1 1 200px;
  max-width: calc(20% - 1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.AboutTeamHeadshotImg {
  width: 100%;
  max-width: 75vw;
  height: auto;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.innderDivImg label {
  text-align: center;
  font-size: clamp(1.2rem, 1.5vw, 1.8rem);
  margin-top: 1rem;
}
.ourMissionAboutDiv {
  padding: 0 10%;
  margin: 0 auto;
}
.ourStoryAboutDiv {
  margin: 0 30rem;
}
@media (hover: hover) {
  .innderDivImg:hover .AboutTeamHeadshotImg {
    transform: scale(1.05);
  }
}

@media screen and (max-width: 1024px) {
  .innderDivImg {
    max-width: calc(33.33% - 1rem);
  }
  .ourMissionAboutDiv,
  .ourStoryAboutDiv {
    padding: 5%;
    margin: 0;
  }
}
@media (max-width: 426px) {
  .mobileTeam {
    display: block;
  }
  .teamDesktop {
    display: none;
  }
  .AboutTeamHeadshotImg {
    max-width: 100vw;
  }
}
@media screen and (max-width: 800px) {
  .innderDivImg {
    max-width: calc(50% - 1rem);
  }
  .aboutH1Team {
    font-size: 60px;
    font-weight: 400;
    line-height: 75.23px;
  }
}

@media screen and (max-width: 480px) {
  .innderDivImg {
    max-width: 100%;
  }

  .imgDivTeam {
    gap: 0.5rem;
  }
}
