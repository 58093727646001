/* menu.css */
.menu-div.open {
  /* background-color: #fff; */
}
.menu-div.open.whitebg {
  background-color: rgba(0, 0, 0, 0);
}
.menu-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 1000;
}

.logo-link {
  text-decoration: none;
  z-index: 1002; /* Ensure logo stays above the menu overlay */
  position: relative; /* Add this to maintain logo position */
}

.menu_header {
  font-family: var(--font-primary);
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--light-blue);
  margin: 0;
}

.menu_header span {
  color: var(--gray);
}

.menu-container {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Align items to the right */
  flex-grow: 1;
}

.menu-list {
  list-style: none;
  display: flex;
  gap: 2rem;
  padding: 0;
  margin: 0;
  width: 80%;
  justify-content: space-evenly;
}

.menu-list li a {
  font-family: var(--font-secondary);
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 34.1px;
  color: var(--gray);
  text-decoration: none;
}

.menu-actions {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.hamburger-bar {
  width: 100%;
  height: 3px;
  background-color: var(--gray);
  transition: all 0.3s linear;
}

@media (max-width: 1400px) {
  .menu-list {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .outerDiv-Menu {
    margin: auto;
    display: flex;
    align-items: center;
    gap: 70vw;
  }
  .menu-div {
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .logo-link {
    flex: 0 0 auto; /* Prevent logo from shrinking */
  }
  .menu-div.whitebg .hamburger-bar {
    background-color: white;
  }
  .hamburger {
    display: flex;
    margin-left: auto; /* Push to the right */
  }

  .hamburger-bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger-bar.open:nth-child(2) {
    opacity: 0;
  }

  .hamburger-bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
  .menu-div.open.whitebg .menu-container {
    /* background: rgba(92, 72, 173, 1); */
  }
  .menu-div.open.whitebg {
    background: rgba(92, 72, 173, 1);
  }
  .menu-container {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    /* padding: 1rem; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .menu-container.open,
  .menu-div.open {
    background: rgba(92, 72, 173, 1);
    width: 100vw;
  }
  .menu-container.open {
    display: flex;
    align-items: flex-start;
    padding: 0 2rem;
    align-items: center;
  }
  .menu-list li a {
    /* margin: 0 4rem; */
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 32.74px;
    color: #fff;
  }
  .menu-container.open {
    height: 70vh;
    border-radius: 0 0 30rem 0;
  }
  .menu-list {
    flex-direction: column;
    /* width: 100%; */
    margin-top: 1rem;
  }

  .menu-actions {
    flex-direction: column;
    /* width: 50%; */
    margin-top: 1rem;
  }

  .menu-actions .btn-btn {
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .menu-div {
    padding: 0.5rem;
  }

  .menu_header {
    font-size: 2.5rem;
  }

  .menu-list li a {
    margin: 0 4rem;
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 32.74px;
    color: #fff;
  }
}

/* Styles for Btn component */
.menu-actions .btn-btn {
  width: auto;
}

@media (max-width: 768px) {
  .menu-actions .btn-btn {
    width: 100%;
  }
}
